import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useAuth } from "hooks/useAuth";
import { APIGetTestsHistory } from "api/pages/tests";
import Loading from "components/ui/Loading";
import CustomDataTable, { Order } from "components/ui/CustomDataTable";
import SearchForm, { IFormInputs } from "components/form/SearchForm";
import { SubmitHandler } from "react-hook-form";
import {
  historyTestsPrincipalsTableColumns,
  historyTestsStudentsTableColumns,
  historyTestsTeachersTableColumns,
} from "utils/testsTableColumns";
import {
  historyTestsTrainingPrincipalsTableColumns,
  historyTestsTrainingStudentsTableColumns,
  historyTestsTrainingTeachersTableColumns,
} from "utils/testTrainingTableColumns";
import {
  historyBacTestsPrincipalsTableColumns,
  historyBacTestsStudentsTableColumns,
  historyBacTestsTeachersTableColumns,
} from "utils/testsBacEvaluateTableColumns";
import moment from "moment";
import { Link } from "react-router-dom";
import GenericButton from "components/ui/GenericButton";
import AdvancedSearchForm, { IFormInputsAdvanced } from "../form/AdvancedSearchForm";
import {getRomanNumber} from "../../utils/functions";
import {SelectChangeEvent} from "@mui/material/Select";


interface Props {
  type?: string;
  pageType?: string;
  pageId?: string;
  fromClassroom?: boolean;
}

const TestsSchoolHistory = ({ type, pageType, pageId, fromClassroom = false }: Props) => {
  const { update, user } = useAuth();

  const [historyTestsTableColumns, setHistoryTestsTableColumns] = useState<any[]>([]);
  const [historyTestsLoading, setHistoryTestsLoading] = useState<boolean>(false);
  const [historyTests, setHistoryTests] = useState<any[]>([]);
  const [historyTestsTotalRows, setHistoryTestsTotalRows] = useState<number>(0);
  const [historyTestsPerPage, setHistoryTestsPerPage] = useState<number>(15);
  const [historyTestsPage, setHistoryTestsPage] = useState<number>(0);
  const [historyTestsOrder, setHistoryTestsOrder] = useState<Order>("desc");
  const [historyTestsOrderBy, setHistoryTestsOrderBy] = useState<string>("test");
  const [historyTestsQuery, setHistoryTestsQuery] = useState<string>("");
  const [historyTestsSearch, setHistoryTestsSearch] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [interval, setInterval] = useState<any[]>([]);
  const [testType, setTestType] = useState(type? type : "school");
  const [subject, setSubject] = useState<string>("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedTest, setSelectedTest] = useState("");

  useEffect(() => {
    setHistoryTestsLoading(true);
    APIGetTestsHistory({
      page: `?page=${historyTestsPage + 1}`,
      sort: historyTestsOrderBy,
      order: historyTestsOrder,
      search: historyTestsQuery,
      startDate: startDate ? moment(startDate).locale("ro").format("YYYY-MM-DD") : "",
      endDate: endDate ? moment(endDate).locale("ro").format("YYYY-MM-DD") : "",
      type: testType,
      pageType: pageType,
      pageTypeId: pageId,
      item_type_id: subject,
    })
      .then((response) => {
        if (response.data.success) {
          // console.log("getHistoryTests OK", response);
          setHistoryTests(
            response.data.data.data.map((test: any) => {
              if(testType === "bac" || testType === "evaluate") {
                  console.log(testType)
                if (user.position === "principal") {
                  return {
                    test: test.test.toString(),
                    test_type: test.test_grade,
                    test_tag: test.test_tag,
                    owner_name: <Link to={`/profesori/${test.owner_id}`}>{test.owner_name}</Link>,
                    user_name: <Link to={`/elevi/${test.user_id}`}>{test.user_name}</Link>,
                    classroom_name: <Link to={`/clase/${test.classroom_id}`}>{test.classroom_grade ? `(${getRomanNumber(test.classroom_grade)}) ${test.classroom_name}` : test.classroom_name}</Link>,
                    test_score: test.test_score,
                    test_time: test.test_time,
                    responses: `${test.test_right_answers}/${test.test_total_answers}`,
                    test_started_at:
                        test.test_started_at != null
                            ? moment(test.test_started_at).locale("ro").format("HH:mm, DD.MM.YYYY")
                            : "Nedeschis",
                    actions: (
                        <Box display="flex" justifyContent="end">
                          <Box mr={1}>
                              <GenericButton
                                  type="report"
                                  onClick={() => {
                                    if (testType === "bac") {
                                      window.open(`/teste-bac/raport/${test.test}`);
                                    } else if (testType === "evaluate") {
                                      window.open(`/teste-evaluare/raport/${test.test}`);
                                    }
                                  }}
                              />
                          </Box>
                          <Box>
                            <GenericButton
                                type="answers"
                                onClick={() => {
                                  if (testType === "bac") {
                                    window.open(`/teste-bac/raspunsuri/${test.test}`);
                                  } else if (testType === "evaluate") {
                                    window.open(`/teste-evaluare/raspunsuri/${test.test}`);
                                  }
                                }}
                            />
                          </Box>
                        </Box>
                    ),
                  };
                } else if (user.position === "teacher") {
                  return {
                    test: test.test.toString(),
                    test_type: test.test_grade,
                    test_tag: test.test_tag,
                    user_name: <Link to={`/elevi/${test.user_id}`}>{test.user_name}</Link>,
                    classroom_name: <Link to={`/clase/${test.classroom_id}`}>{test.classroom_grade ? `(${getRomanNumber(test.classroom_grade)}) ${test.classroom_name}` : test.classroom_name}</Link>,
                    test_score: test.test_score,
                    test_time: test.test_time,
                    responses: `${test.test_right_answers}/${test.test_total_answers}`,
                    test_started_at:
                        test.test_started_at != null
                            ? moment(test.test_started_at).locale("ro").format("HH:mm, DD.MM.YYYY")
                            : "Nedeschis",
                    actions: (
                        <Box display="flex" justifyContent="end">
                          <Box mr={1}>
                              <GenericButton
                                  type="report"
                                  onClick={() => {
                                    if (testType === "bac") {
                                      window.open(`/teste-bac/raport/${test.test}`);
                                    } else if (testType === "evaluate") {
                                      window.open(`/teste-evaluare/raport/${test.test}`);
                                    }
                                  }}
                              />
                          </Box>
                          <Box>
                            <GenericButton
                                type="answers"
                                onClick={() => {
                                  if (testType === "bac") {
                                    window.open(`/teste-bac/raspunsuri/${test.test}`);
                                  } else if (testType === "evaluate") {
                                    window.open(`/teste-evaluare/raspunsuri/${test.test}`);
                                  }
                                }}
                            />
                          </Box>
                        </Box>
                    ),
                  };
                } else if (user.position === "student") {
                  return {
                    test: test.test.toString(),
                    test_type: test.test_grade,
                    owner_name: test.owner_name,
                    classroom_name: test.classroom_grade ? `(${getRomanNumber(test.classroom_grade)}) ${test.classroom_name}` : test.classroom_name,
                    score: test.test_score,
                    test_time: test.test_time,
                    responses: `${test.test_right_answers}/${test.test_total_answers}`,
                    test_started_at:
                        test.test_started_at != null
                            ? moment(test.test_started_at).locale("ro").format("HH:mm, DD.MM.YYYY")
                            : "Nedeschis",
                    actions: (
                        <Box display="flex" justifyContent="end">
                          <Box mr={1}>
                              <GenericButton
                                  type="report"
                                  onClick={() => {
                                    if (testType === "bac") {
                                      window.open(`/teste-bac/raport/${test.test}`);
                                    } else if (testType === "evaluate") {
                                      window.open(`/teste-evaluare/raport/${test.test}`);
                                    }
                                  }}
                              />
                          </Box>
                          <Box>
                            <GenericButton
                                type="answers"
                                onClick={() => {
                                  if (testType === "bac") {
                                    window.open(`/teste-bac/raspunsuri/${test.test}`);
                                  } else if (testType === "evaluate") {
                                    window.open(`/teste-evaluare/raspunsuri/${test.test}`);
                                  }
                                }}
                            />
                          </Box>
                        </Box>
                    ),
                  };
                }
              } else if (testType === "training" || testType === "customTest") {
                if (user.position === "principal") {
                  return {
                    test: test.test.toString(),
                    test_type: test.test_type,
                    test_tag: test.test_tag,
                    test_grade: test.test_grade,
                    owner_name: <Link to={`/profesori/${test.owner_id}`}>{test.owner_name}</Link>,
                    user_name: <Link to={`/elevi/${test.user_id}`}>{test.user_name}</Link>,
                    classroom_name: <Link to={`/clase/${test.classroom_id}`}>{test.classroom_grade ? `(${getRomanNumber(test.classroom_grade)}) ${test.classroom_name}` : test.classroom_name}</Link>,
                    test_time: test.test_time,
                    responses: `${test.test_right_answers}/${test.test_total_answers}`,
                    test_started_at:
                      test.test_started_at != null
                        ? moment(test.test_started_at).locale("ro").format("HH:mm, DD.MM.YYYY")
                        : "Nedeschis",
                    actions: (
                      <Box display="flex" justifyContent="end">
                        <Box>
                          <GenericButton
                            type="answers"
                            onClick={() => {
                                if(testType === "training") {
                                    window.open(`/teste-antrenament/raspunsuri/${test.test}`);
                                } else if(testType === "customTest") {
                                    window.open(`/teste-proprii/raspunsuri/${test.test}`);
                                }
                            }}
                          />
                        </Box>
                      </Box>
                    ),
                  };
                } else if (user.position === "teacher") {
                  return {
                    test: test.test.toString(),
                    test_type: test.test_type,
                    test_tag: test.test_tag,
                    test_grade: test.test_grade,
                    user_name: <Link to={`/elevi/${test.user_id}`}>{test.user_name}</Link>,
                    classroom_name: <Link to={`/clase/${test.classroom_id}`}>{test.classroom_grade ? `(${getRomanNumber(test.classroom_grade)}) ${test.classroom_name}` : test.classroom_name}</Link>,
                    test_time: test.test_time,
                    responses: `${test.test_right_answers}/${test.test_total_answers}`,
                    test_started_at:
                      test.test_started_at != null
                        ? moment(test.test_started_at).locale("ro").format("HH:mm, DD.MM.YYYY")
                        : "Nedeschis",
                    actions: (
                      <Box display="flex" justifyContent="end">
                        <Box>
                          <GenericButton
                            type="answers"
                            onClick={() => {
                                if(testType === "training") {
                                    window.open(`/teste-antrenament/raspunsuri/${test.test}`);
                                } else if(testType === "customTest") {
                                    window.open(`/teste-proprii/raspunsuri/${test.test}`);
                                }
                            }}
                          />
                        </Box>
                      </Box>
                    ),
                  };
                } else if (user.position === "student") {
                  return {
                    test: test.test.toString(),
                    test_type: test.test_type,
                    test_grade: test.test_grade,
                    owner_name: test.owner_name,
                    classroom_name: test.classroom_grade ? `(${getRomanNumber(test.classroom_grade)}) ${test.classroom_name}` : test.classroom_name,
                    test_time: test.test_time,
                    responses: `${test.test_right_answers}/${test.test_total_answers}`,
                    test_started_at:
                      test.test_started_at != null
                        ? moment(test.test_started_at).locale("ro").format("HH:mm, DD.MM.YYYY")
                        : "Nedeschis",
                    actions: (
                      <Box display="flex" justifyContent="end">
                        <Box>
                          <GenericButton
                            type="answers"
                            onClick={() => {
                                if(testType === "training") {
                                    window.open(`/teste-antrenament/raspunsuri/${test.test}`);
                                } else if(testType === "customTest") {
                                    window.open(`/teste-proprii/raspunsuri/${test.test}`);
                                }
                            }}
                          />
                        </Box>
                      </Box>
                    ),
                  };
                }
              } else {
                if (user.position === "principal") {
                  return {
                    test: test.test.toString(),
                    test_type: test.test_type,
                    test_tag: test.test_tag,
                    test_grade: test.test_grade,
                    owner_name: <Link to={`/profesori/${test.owner_id}`}>{test.owner_name}</Link>,
                    user_name: <Link to={`/elevi/${test.user_id}`}>{test.user_name}</Link>,
                    classroom_name: <Link to={`/clase/${test.classroom_id}`}>{test.classroom_grade ? `(${getRomanNumber(test.classroom_grade)}) ${test.classroom_name}` : test.classroom_name}</Link>,
                    test_score: test.test_score,
                    test_time: test.test_time,
                    responses: `${test.test_right_answers}/${test.test_total_answers}`,
                    test_started_at:
                      test.test_started_at != null
                        ? moment(test.test_started_at).locale("ro").format("HH:mm, DD.MM.YYYY")
                        : "Nedeschis",
                    actions: (
                      <Box display="flex" justifyContent="end">
                        <Box mr={1}>
                          {testType !== "training" && (
                            <GenericButton
                              type="report"
                              onClick={() => {
                                if (testType === "school") {
                                  window.open(`/teste/raport/${test.test}`);
                                } else if (testType === "bac") {
                                  window.open(`/teste-bac/raport/${test.test}`);
                                } else if (testType === "evaluate") {
                                  window.open(`/teste-evaluare/raport/${test.test}`);
                                } else if (testType === "literacy") {
                                  window.open(`/teste-literatie/raport/${test.test}`);
                                } else if (testType === "digitalLiteracy") {
                                  window.open(`/teste-literatie-digitala/raport/${test.test}`);
                                }
                              }}
                            />
                          )}
                        </Box>
                        <Box>
                          <GenericButton
                            type="answers"
                            onClick={() => {
                              if (testType === "school") {
                                window.open(`/teste/raspunsuri/${test.test}`);
                              } else if (testType === "bac") {
                                window.open(`/teste-bac/raspunsuri/${test.test}`);
                              } else if (testType === "evaluate") {
                                window.open(`/teste-evaluare/raspunsuri/${test.test}`);
                              } else if (testType === "literacy") {
                                window.open(`/teste-literatie/raspunsuri/${test.test}`);
                              } else if (testType === "digitalLiteracy") {
                                window.open(`/teste-literatie-digitala/raspunsuri/${test.test}`);
                              }
                            }}
                          />
                        </Box>
                      </Box>
                    ),
                  };
                } else if (user.position === "teacher") {
                  return {
                    test: test.test.toString(),
                    test_type: test.test_type,
                    test_tag: test.test_tag,
                    test_grade: test.test_grade,
                    user_name: <Link to={`/elevi/${test.user_id}`}>{test.user_name}</Link>,
                    classroom_name: <Link to={`/clase/${test.classroom_id}`}>{test.classroom_grade ? `(${getRomanNumber(test.classroom_grade)}) ${test.classroom_name}` : test.classroom_name}</Link>,
                    test_score: test.test_score,
                    test_time: test.test_time,
                    responses: `${test.test_right_answers}/${test.test_total_answers}`,
                    test_started_at:
                      test.test_started_at != null
                        ? moment(test.test_started_at).locale("ro").format("HH:mm, DD.MM.YYYY")
                        : "Nedeschis",
                    actions: (
                      <Box display="flex" justifyContent="end">
                        <Box mr={1}>
                          {testType !== "training" && (
                            <GenericButton
                              type="report"
                              onClick={() => {
                                if (testType === "school") {
                                  window.open(`/teste/raport/${test.test}`);
                                } else if (testType === "bac") {
                                  window.open(`/teste-bac/raport/${test.test}`);
                                } else if (testType === "evaluate") {
                                  window.open(`/teste-evaluare/raport/${test.test}`);
                                } else if (testType === "literacy") {
                                  window.open(`/teste-literatie/raport/${test.test}`);
                                } else if (testType === "digitalLiteracy") {
                                  window.open(`/teste-literatie-digitala/raport/${test.test}`);
                                }
                              }}
                            />
                          )}
                        </Box>
                        <Box>
                          <GenericButton
                            type="answers"
                            onClick={() => {
                              if (testType === "school") {
                                window.open(`/teste/raspunsuri/${test.test}`);
                              } else if (testType === "bac") {
                                window.open(`/teste-bac/raspunsuri/${test.test}`);
                              } else if (testType === "evaluate") {
                                window.open(`/teste-evaluare/raspunsuri/${test.test}`);
                              } else if (testType === "literacy") {
                                window.open(`/teste-literatie/raspunsuri/${test.test}`);
                              } else if (testType === "digitalLiteracy") {
                                window.open(`/teste-literatie-digitala/raspunsuri/${test.test}`);
                              }
                            }}
                          />
                        </Box>
                      </Box>
                    ),
                  };
                } else if (user.position === "student") {
                  return {
                    test: test.test.toString(),
                    test_type: test.test_type,
                    test_grade: test.test_grade,
                    owner_name: test.owner_name,
                    classroom_name: test.classroom_grade ? `(${getRomanNumber(test.classroom_grade)}) ${test.classroom_name}` : test.classroom_name,
                    score: test.test_score,
                    test_time: test.test_time,
                    responses: `${test.test_right_answers}/${test.test_total_answers}`,
                    test_started_at:
                      test.test_started_at != null
                        ? moment(test.test_started_at).locale("ro").format("HH:mm, DD.MM.YYYY")
                        : "Nedeschis",
                    actions: (
                      <Box display="flex" justifyContent="end">
                        <Box mr={1}>
                          {type !== "training" && (
                            <GenericButton
                              type="report"
                              onClick={() => {
                                if (testType === "school") {
                                  window.open(`/teste/raport/${test.test}`);
                                } else if (testType === "bac") {
                                  window.open(`/teste-bac/raport/${test.test}`);
                                } else if (testType === "evaluate") {
                                  window.open(`/teste-evaluare/raport/${test.test}`);
                                } else if (testType === "literacy") {
                                  window.open(`/teste-literatie/raport/${test.test}`);
                                } else if (testType === "digitalLiteracy") {
                                  window.open(`/teste-literatie-digitala/raport/${test.test}`);
                                }
                              }}
                            />
                          )}
                        </Box>
                        <Box>
                          <GenericButton
                            type="answers"
                            onClick={() => {
                              if (testType === "school") {
                                window.open(`/teste/raspunsuri/${test.test}`);
                              } else if (testType === "bac") {
                                window.open(`/teste-bac/raspunsuri/${test.test}`);
                              } else if (testType === "evaluate") {
                                window.open(`/teste-evaluare/raspunsuri/${test.test}`);
                              } else if (testType === "literacy") {
                                window.open(`/teste-literatie/raspunsuri/${test.test}`);
                              } else if (testType === "digitalLiteracy") {
                                window.open(`/teste-literatie-digitala/raspunsuri/${test.test}`);
                              }
                            }}
                          />
                        </Box>
                      </Box>
                    ),
                  };
                }
              }

              return {};
            }),
          );
          setHistoryTestsTotalRows(response.data.data.total);
          setHistoryTestsPerPage(response.data.data.per_page);
          setHistoryTestsLoading(false);
        } else {
          update("error", { show: true, code: "A002" });
        }
      })
      .catch((err) => {
        // console.log("getHistoryTests ERR", err);
        update("error", { show: true, code: "A001" });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyTestsPage, historyTestsOrderBy, historyTestsOrder, historyTestsQuery, historyTestsSearch, interval, selectedSubject, selectedTest]);

  useEffect(() => {
    if (user.position === "principal") {
      if (testType === "training" || testType === "customTest") {
        setHistoryTestsTableColumns(historyTestsTrainingPrincipalsTableColumns);
      } else if (testType === "bac" || testType === "evaluate") {
        setHistoryTestsTableColumns(historyBacTestsPrincipalsTableColumns);
      } else {
        setHistoryTestsTableColumns(historyTestsPrincipalsTableColumns);
      }
    } else if (user.position === "teacher") {
      if (testType === "training" || testType === "customTest") {
        setHistoryTestsTableColumns(historyTestsTrainingTeachersTableColumns);
      } else if (testType === "bac" || testType === "evaluate") {
        setHistoryTestsTableColumns(historyBacTestsTeachersTableColumns);
      } else {
        setHistoryTestsTableColumns(historyTestsTeachersTableColumns);
      }
    } else if (user.position === "student") {
      if (testType === "training" || testType === "customTest") {
        setHistoryTestsTableColumns(historyTestsTrainingStudentsTableColumns);
      } else if (testType === "bac" || testType === "evaluate") {
        setHistoryTestsTableColumns(historyBacTestsStudentsTableColumns);
      } else {
        setHistoryTestsTableColumns(historyTestsStudentsTableColumns);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, user, selectedTest]);

    const setSubjectHandler = (event: SelectChangeEvent) => {
        const target = event.target as HTMLInputElement;
        setSubject(target.value);
    }

    const handleSelectChange = (e: React.FormEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        setTestType(target.value);
        setSubject("");
    }

  const handleHistoryTestsChangePage = (event: unknown, newPage: number) => {
    setHistoryTests([]);
    setHistoryTestsPage(newPage);
  };

  const handleHistoryTestsRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = historyTestsOrderBy === property && historyTestsOrder === "asc";
    setHistoryTestsOrder(isAsc ? "desc" : "asc");
    setHistoryTestsOrderBy(property);
    setHistoryTestsPage(0);
  };

  const handleHistoryTestsSearch: SubmitHandler<IFormInputsAdvanced> = (data) => {
    setSelectedTest(testType);
    setSelectedSubject(subject);
    setHistoryTestsQuery(data.keywords);
    setHistoryTestsPage(0);
    setHistoryTestsSearch(true);
    if(startDate && endDate) {
      setInterval([startDate, endDate]);
    }
  };

  const handleClearSubject = () => {
      setSubject("");
      setHistoryTestsSearch(false);
      setHistoryTestsPage(0);
  }

  const handleHistoryTestsClearSearch = () => {
    setHistoryTestsQuery("");
    setHistoryTestsSearch(false);
    setHistoryTestsPage(0);
  };

  const onClearDateRange = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    setHistoryTestsPage(0);
    setHistoryTestsSearch(false);
    setInterval([]);
  }

  const onDateRangeChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <>
      <Box sx={(theme) => ({marginBottom: { xs: 1, sm: 3 },})}>
          {user.position === "student" ? (
              <Box display="flex" justifyContent="end" width="100%">
                  <SearchForm
                      onSubmit={handleHistoryTestsSearch}
                      onChange={(e) => setHistoryTestsQuery(e.target.value)}
                      onClick={handleHistoryTestsClearSearch}
                      query={historyTestsQuery}
                  />
              </Box>
          ) : (
              <AdvancedSearchForm
                  onSubmit={handleHistoryTestsSearch}
                  onChange={(e) => setHistoryTestsQuery(e.target.value)}
                  onClearSearch={handleHistoryTestsClearSearch}
                  query={historyTestsQuery}
                  startDate={startDate}
                  endDate={endDate}
                  onDateRangeChange={onDateRangeChange}
                  onClearDateRange={onClearDateRange}
                  interval={interval}
                  type={testType}
                  page={historyTestsPage}
                  sort={historyTestsOrderBy}
                  order={historyTestsOrder}
                  fromClassroom={fromClassroom}
                  testType={testType}
                  handleSelectChange={handleSelectChange}
                  setSubjectHandler={setSubjectHandler}
                  subject={subject}
                  onClearSubject={handleClearSubject}
              />
          ) }
      </Box>
      {historyTestsLoading ? (
        <Loading show={true} />
      ) : historyTests.length === 0 ? (
        <Box>Niciun test finalizat.</Box>
      ) : (
        <CustomDataTable
          columns={historyTestsTableColumns}
          rows={historyTests}
          rowsPerPage={historyTestsPerPage}
          page={historyTestsPage}
          totalRows={historyTestsTotalRows}
          onPageChange={handleHistoryTestsChangePage}
          handleRequestSort={handleHistoryTestsRequestSort}
          orderBy={historyTestsOrderBy}
          order={historyTestsOrder}
          // hasSelect={true}
        />
      )}
    </>
  );
};

export default TestsSchoolHistory;
