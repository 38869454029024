import React from "react";
import Box from "@mui/material/Box";
import {SystemStyleObject} from "@mui/system";
import {Theme} from "@mui/material";

type Props = {
  children: JSX.Element[] | JSX.Element | string;
  borderSuccess?: boolean;
  [key: string]: any;
  dashboardBox?: boolean;
  zIndex?: number;
  sxExtra?: SystemStyleObject<Theme> | undefined;
};

const BoxContent = ({ children, borderSuccess, dashboardBox = false, zIndex, sxExtra = {}, ...rest }: Props) => {
  return (
    <Box
      sx={{
        padding: dashboardBox ? 3 : { xs: 1.5, md: 3 },
        width: "100%",
        height: "100%",
        bgcolor: "background.paper",
        borderRadius: 1.5,
        position: "relative",
        zIndex: zIndex ? zIndex : dashboardBox ? "unset" : 0,
        border: borderSuccess ? "1px solid" : "none",
        borderColor: "success.main",
        ...sxExtra
      }}
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      {...rest}
    >
      {children}
    </Box>
  );
};

export default BoxContent;
