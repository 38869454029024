import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormField from "components/form/FormField";
import CustomDatePicker from "./CustomDatePicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import IconButton from "@mui/material/IconButton";
import ExportButton from "./ExportButton";
import Icon from "../ui/Icon";
import CustomButton from "../ui/CustomButton";

export interface IFormInputsAdvanced {
    keywords: string;
    searchType: string;
    subject: string;
    testType: string;
}

interface ISearchForm {
    onSubmit: (data: any) => void;
    query: string;
    onChange: (e: any) => void;
    onClearSearch: () => void;
    onClearSubject?: () => void;
    onClearTestType?: () => void;
    startDate: Date | undefined;
    endDate: Date | undefined;
    onDateRangeChange: (dates: any) => void;
    onClearDateRange: () => void;
    interval: any[];
    type: string;
    order: string;
    page: number;
    sort: string;
    fromClassroom?: boolean;
    testType?: string;
    handleSelectChange?: (e:any) => void;
    setSubjectHandler?: (e:any) => void;
    subject?: string;
    archived?: boolean;
}

const schema = yup.object({
    keywords: yup.string(),
});

const AdvancedSearchForm = (
    { onSubmit,
        query,
        onChange,
        onClearSearch,
        endDate,
        startDate,
        onDateRangeChange,
        onClearDateRange,
        interval,
        type,
        order,
        page,
        sort,
        fromClassroom = false,
        testType,
        handleSelectChange = (e) => {},
        setSubjectHandler = (e) => {},
        subject,
        onClearSubject = () => {},
        archived = false,
    }: ISearchForm) => {

    const methods = useForm<IFormInputsAdvanced>({
        resolver: yupResolver(schema),
    });
    const [subjects, setSubjects] = useState<any[]>([]);
    const selectOptions = [
        {
            label: "Teste școlare",
            value: "school",
        },
        {
            label: "Teste pregătire BAC",
            value: "bac",
        },
        {
            label: "Teste pregătire Evaluare Națională",
            value: "evaluate",
        },
        {
            label: "Teste de antrenament",
            value: "training",
        },
        {
            label: "Teste de literație",
            value: "literacy",
        },
        {
            label: "Teste de literație digitală",
            value: "digitalLiteracy",
        },
    ];

    useEffect(() => {
        if (testType === "bac") {
            setSubjects([
                { name: "Bac Mate/Științe", id: "bac-mate" },
                { name: "Bac Mate-Info", id: "bac-mate-info" },
                { name: "Bac Română", id: "bac-romana" },
            ]);
        } else if (testType === "evaluate") {
            setSubjects([
                { name: "Evaluare nationala matematica", id: "evaluare-nationala-mate" },
                { name: "Evaluare nationala romana", id: "evaluare-nationala-romana" },
            ]);
        } else if (testType === "school" || testType === "training") {
            setSubjects([
                { name: "Istorie", id: "22" },
                { name: "Matematică", id: "1" },
                { name: "Română", id: "4" },
                { name: "Fizică", id: "30" },
                { name: "Chimie", id: "34" },
                { name: "Biologie", id: "33" },
                { name: "Geografie", id: "37" },]);
        } else {
            setSubjects([]);
        }
    }, [testType]);

    const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                <Box flexDirection="row" sx={{display: {xs: "block", md: "flex"}, justifyContent: "flex-end", flexWrap: 'wrap'}}>
                    {fromClassroom && (
                        <Box sx={(theme) => ({
                            borderRadius: theme.spacing(0.6),
                            backgroundColor: "secondary.contrastText",
                            marginRight: 0.5,
                            minWidth: {xs: "100%", md: "200px"},
                            maxWidth: "200px",
                            marginBottom: 1
                        })}>
                            <FormField
                                name="testType"
                                type="controlled-select-small"
                                label="Alege tipul testelor"
                                defaultValue={testType}
                                value={testType}
                                onChange={handleSelectChange}
                                options={selectOptions}
                                errorMessage={methods.formState.errors.testType?.message}
                            />
                        </Box>
                    )}

                    {(!fromClassroom && (testType === "literacy" || testType === "digitalLiteracy")) ? (
                       <></>
                    ) : (
                        <Box sx={(theme) => ({
                            borderRadius: theme.spacing(0.6),
                            backgroundColor: "secondary.contrastText",
                            marginRight: 0.5,
                            marginBottom: 1,
                            minWidth: {xs: "100%", md: "200px"},
                            maxWidth: "200px",
                        })}>
                        <FormField
                            disabled={testType === "literacy" || testType === "digitalLiteracy"}
                            name="subject"
                            type="controlled-select-small"
                            label="Alege materia"
                            defaultValue={subject}
                            value={subject}
                            onChange={setSubjectHandler}
                            onClick={() => {
                                onClearSubject();
                                methods.reset();
                            }}
                            options={subjects.map((item: { name: string; id: string }) => {
                                return {
                                    label: item.name,
                                    value: item.id,
                                    ...item,
                                };
                            })}
                            errorMessage={methods.formState.errors.subject?.message}
                        />
                        </Box>
                    )}

                    <Box sx={(theme) => ({
                        borderRadius: theme.spacing(0.6),
                        backgroundColor: "secondary.contrastText",
                        marginRight: 0.5,
                        marginBottom: 1,
                        minWidth: {xs: "100%", md: "200px"},
                        maxWidth: "200px",
                    })}>
                        <FormField
                            name="keywords"
                            type="text-icon"
                            label="Caută..."
                            autoComplete = "off"
                            errorMessage={methods.formState.errors.keywords?.message}
                            className="dark"
                            defaultValue={query}
                            value={query}
                            onChange={onChange}
                            onClick={() => {
                                onClearSearch();
                                methods.reset();
                            }}
                        />
                    </Box>
                    <Box sx={{
                        marginRight: 0.5,
                        minWidth: {xs: "100%", md: "260px"},
                        maxWidth: "260px",
                        marginBottom: 1
                    }}>
                        <Box
                            display="flex"
                            alignItems="center"
                            sx={(theme) => ({
                                paddingRight: theme.spacing(1.5),
                                // marginRight: -0.6,
                                borderRadius: theme.spacing(0.6),
                            })}
                            bgcolor="secondary.contrastText"
                        >
                            <CustomDatePicker
                                name="Interval"
                                startDate={startDate}
                                endDate={endDate}
                                onDateChange={onDateRangeChange}
                                type="dateRangePicker"
                            />
                            <IconButton
                                sx={{ visibility: interval.length > 0 ? "visible" : "hidden" }}
                                onClick={onClearDateRange}
                                onMouseDown={handleMouseDown}
                                edge="end"
                            >
                                <FontAwesomeIcon style={{ fontSize: "1rem", color: "#9D0000" }} icon={"xmark"} />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box width="auto">
                        <CustomButton
                            bgcolor="dark"
                            submit
                            buttonType="textIcon"
                            icon={<Icon icon="far magnifying-glass" fixedWidth={true}/>}
                        >
                            Caută
                        </CustomButton>
                    </Box>
                    {!fromClassroom && !archived && (
                        <Box sx={{marginLeft: {xs: 0, md: 0.5}, marginTop: {xs: 1, md: 0}}}>
                            <ExportButton
                                type={type}
                                endDate={endDate}
                                order={order}
                                page={page}
                                startDate={startDate}
                                search={query}
                                sort={sort}
                            />
                        </Box>
                    )}
                </Box>
            </form>
        </FormProvider>
    );
};

export default AdvancedSearchForm;
