import React, { useEffect, useState, FC } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Link, useNavigate, useParams} from "react-router-dom";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import BoxContent from "components/ui/BoxContent";
import PageTitle from "components/ui/PageTitle";
import { APIGetClassroom } from "api/pages/classroom";
import { APIGetTeachers } from "api/pages/teachers";
import { APIGetStudents } from "api/pages/students";
import Loading from "components/ui/Loading";
import { useAuth } from "hooks/useAuth";
import { APIUpdateClassroom, APIPostClassroom } from "api/pages/classroom";
import FormField, {TextFieldStyled} from "components/form/FormField";
import ErrorsList from "components/ui/ErrorsList";
import { useLocation } from "react-router-dom";
import AvatarItemList from "components/ui/AvatarItemList";
import {arrayEquals, getRomanNumber, gradesRomans, useRouteMatch} from "utils/functions";
import FormLabelBox from "components/ui/FormLabelBox";
import FormFieldBox from "components/ui/FormFieldBox";
import List from "@mui/material/List";
import GenericButton from "components/ui/GenericButton";
import CustomButton from "../../components/ui/CustomButton";
import DashboardStatistics from "../../components/dashboard/DashboardStatistics";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TestsHistory from "../../components/tests/TestsHistory";

interface IFormClassroomEdit {
  name: string;
  grade: number;
  teachers: any[];
  students: any[];
}

const schema = yup
  .object({
  })
  .required();

const Classroom: FC = () => {
  const { id = "", action } = useParams();
  const { update, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const routeMatch = useRouteMatch([`/clase/${id}`, `/clase/${id}/elevi`, `/clase/${id}/profesori`, `/clase/${id}/teste`]);
  const currentTab = routeMatch?.pattern?.path;

  const methods = useForm<IFormClassroomEdit>({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [classroom, setClassroom] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    if (id !== "") {
      setLoading(true);
      APIGetClassroom({ id: id })
        .then((response) => {
          if (response.data.success) {
            // console.log("getClassroom OK", response);
            setClassroom(response.data.data.item);
            setLoading(false);
          } else {
            update("error", { show: true, code: "A002" });
          }
        })
        .catch((err) => {
          // console.log("getClassroom ERR", err);
          update("error", { show: true, code: "A001" });
        });
    } else {
      let locationArray = location.pathname.split("/");
      if (locationArray[locationArray.length - 1] === "adauga") {
        setClassroom({
          name: "",
          grade: "",
        });
        setEditMode(true);
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id !== "") {
      methods.clearErrors();
      setEditMode(action === "editare");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, id]);

  const onSubmit: SubmitHandler<IFormClassroomEdit> = (data) => {
    let classroomTeachers: number[] = [];
    let classroomStudents: number[] = [];
    let oldClassroomTeachers: number[] = [];
    let oldClassroomStudents: number[] = [];

    data.teachers && (classroomTeachers = data.teachers.map(({ value }) => value));
    data.students && (classroomStudents = data.students.map(({ value }) => value));
    classroom.teachers && (oldClassroomTeachers = classroom.teachers.map(({ id }: any) => id));
    classroom.students && (oldClassroomStudents = classroom.students.map(({ id }: any) => id));

    const users = [...classroomTeachers, ...classroomStudents];

    if (
      data.name !== classroom.name ||
      data.grade !== classroom.grade ||
      !arrayEquals(classroomTeachers, oldClassroomTeachers) ||
      !arrayEquals(classroomStudents, oldClassroomStudents)
    ) {
      methods.clearErrors();
      setErrorMessage("");
      update("loading", { show: true, modal: true });
      if (id !== "") {
        APIUpdateClassroom(id, {
          // ...(data.name !== classroom.name && { name: data.name }),
          // ...(data.grade !== classroom.grade && { grade: data.grade }),
          // ...((!arrayEquals(classroomTeachers, oldClassroomTeachers) ||
          //   !arrayEquals(classroomStudents, oldClassroomStudents)) && { users: users }),
          name: data.name,
          grade: data.grade,
          users: users,
        })
          .then((response) => {
            if (response.data.success) {
              // console.log("updateClassroom OK", response);
              // logic after update

              const updateClassroom = {
                ...classroom,
                ...{ name: data.name, grade: data.grade, students: data.students },
              };

              if (user.position === "teacher") {
                setClassroom(updateClassroom);
              } else {
                setClassroom(data);
              }
              // setEditMode(false);
              update("loading", { show: false });
              navigate(-1);
            } else {
              update("loading", { show: false });
              update("error", { show: true, code: "A002" });
            }
          })
          .catch((err) => {
            // console.log("updateClassroom ERR", err);
            if (err.response.status === 400) {
              let errors = err.response.data.data;
              if (errors.name) {
                methods.setError("name", { type: "server", message: errors.name[0] });
              }
              if (errors.grade) {
                methods.setError("grade", { type: "server", message: errors.grade[0] });
              }
              update("loading", { show: false });
            } else {
              update("error", { show: true, code: "A001" });
            }
          });
      } else {
        APIPostClassroom({
          name: data.name,
          grade: data.grade,
          users: users,
        })
          .then((response) => {
            if (response.data.success) {
              // console.log("postClassroom OK", response);
              // logic after update
              setClassroom(data);
              setEditMode(false);
              navigate(`/clase/${response.data.data.item.id}`, { replace: true });
              update("loading", { show: false });
            } else {
              update("loading", { show: false });
              update("error", { show: true, code: "A002" });
            }
          })
          .catch((err) => {
            // console.log("postClassroom ERR", err);
            if (err.response.status === 400) {
              let errors = err.response.data.data;
              if (errors.name) {
                methods.setError("name", { type: "server", message: errors.name[0] });
              }
              if (errors.grade) {
                methods.setError("grade", { type: "server", message: errors.grade[0] });
              }
              update("loading", { show: false });
            } else {
              update("error", { show: true, code: "A001" });
            }
          });
      }
    } else {
      setErrorMessage("Nu ați modificat niciun câmp.");
    }
  };

  return (
    <Box component="main">
      {loading ? (
        <Loading show={true} />
      ) : (
        <>
          <PageTitle
            back={true}
            actions={
              editMode ? (
                <GenericButton
                  type="close"
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              ) : (
                <GenericButton
                  type="edit"
                  onClick={() => {
                    navigate(`/clase/${id}/editare`);
                  }}
                />
              )
            }
          >
            {id !== "" ? (
              <>
                Clasa <strong style={{ fontWeight: 400 }}>{classroom.name}</strong>
              </>
            ) : (
              <>Adaugă clasă</>
            )}
          </PageTitle>

          <BoxContent>
            <Box width="100%">
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                  <Grid container spacing={1.5} alignItems="center">
                    <FormLabelBox title="Nume:" />

                    <FormFieldBox>
                      {!editMode ? (
                        classroom.name
                      ) : (
                        <FormField
                          name="name"
                          type="text"
                          defaultValue={classroom.name}
                          label="Nume"
                          autoFocus={true}
                          errorMessage={methods.formState.errors.name?.message}
                          error={!!methods.formState.errors.name?.message}
                        />
                      )}
                    </FormFieldBox>

                    <FormLabelBox title="Clasă:" />

                    <FormFieldBox>
                      {!editMode ? (
                        getRomanNumber(classroom.grade)
                      ) : (
                        <FormField
                          name="grade"
                          type="select"
                          defaultValue={classroom.grade}
                          options={gradesRomans.map((grade, index) => {
                            return {
                              label: grade,
                              value: index,
                            };
                          })}
                          label="Clasă"
                          errorMessage={methods.formState.errors.grade?.message}
                          error={!!methods.formState.errors.grade?.message}
                        />
                      )}
                    </FormFieldBox>

                    {editMode && user.position === "principal" && (
                      <>
                        <FormLabelBox title="Profesori:" />

                        <FormFieldBox>
                          <FormField
                            name="teachers"
                            type="autocomplete_api"
                            label="Caută profesor..."
                            optionsAPI={APIGetTeachers}
                            multiple={true}
                            filterOptions={(x: any) => x}
                            defaultValue={
                              classroom.teachers
                                ? classroom.teachers.map(
                                    (item: { last_name: string; first_name: string; id: string }) => {
                                      return {
                                        label: `${item.first_name} ${item.last_name}`,
                                        value: item.id,
                                        ...item,
                                      };
                                    },
                                  )
                                : []
                            }
                            errorMessage={methods.formState.errors.teachers?.message}
                            error={!!methods.formState.errors.teachers?.message}
                          />
                        </FormFieldBox>
                      </>
                    )}

                    {editMode && (
                      <>
                        <FormLabelBox title="Elevi:" />

                        <FormFieldBox>
                          <FormField
                            name="students"
                            type="autocomplete_api"
                            label="Caută elev..."
                            optionsAPI={APIGetStudents}
                            multiple={true}
                            filterOptions={(x: any) => x}
                            defaultValue={
                              classroom.students
                                ? classroom.students.map(
                                    (item: { last_name: string; first_name: string; id: string }) => {
                                      return {
                                        label: `${item.first_name} ${item.last_name}`,
                                        value: item.id,
                                        ...item,
                                      };
                                    },
                                  )
                                : []
                            }
                            errorMessage={methods.formState.errors.students?.message}
                            error={!!methods.formState.errors.students?.message}
                          />
                        </FormFieldBox>
                        <FormLabelBox />
                        <FormFieldBox>
                          <CustomButton submit buttonType="textIcon" bgcolor="dark">
                            {id !== "" ? "SALVEAZĂ CLASA" : "ADAUGĂ CLASĂ"}
                          </CustomButton>
                          <ErrorsList show={errorMessage !== ""}>{errorMessage}</ErrorsList>
                        </FormFieldBox>
                      </>
                    )}
                  </Grid>
                </form>
              </FormProvider>
            </Box>
          </BoxContent>
          {!editMode && (
            <BoxContent mt={3}>
              <Box sx={{ width: "100%" }}>
                <Box mb={2} sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={currentTab}
                    sx={{
                      "& .MuiTabs-flexContainer": {
                        flexWrap: "wrap",
                      },
                    }}
                  >
                    <Tab
                      label="Statistici"
                      value={`/clase/${id}`}
                      to={`/clase/${id}`}
                      component={Link}
                    />
                    <Tab
                        label="Teste"
                        value={`/clase/${id}/teste`}
                        to={`/clase/${id}/teste`}
                        component={Link}
                    />
                    <Tab
                      label="Elevi"
                      value={`/clase/${id}/elevi`}
                      to={`/clase/${id}/elevi`}
                      component={Link}
                    />
                    <Tab
                      label="Profesori"
                      value={`/clase/${id}/profesori`}
                      to={`/clase/${id}/profesori`}
                      component={Link}
                    />
                  </Tabs>
                </Box>
                {location.pathname === `/clase/${id}/elevi` && (
                  <>
                    {!classroom.students || classroom.students.length === 0 ? (
                      <Typography>Nu sunt elevi adaugați</Typography>
                    ) : (
                      <Grid container spacing={1}>
                        {classroom.students.map((student: any, index: any) => (
                            <Grid item xs={12} md={6} key={index}>
                              <List>
                                <AvatarItemList
                                    firstname={student.first_name}
                                    lastname={student.last_name}
                                    profilePhoto={student.profile_photo}
                                    onClick={() => {
                                      navigate(`/elevi/${student.id}`);
                                    }}
                                />
                              </List>
                            </Grid>
                        ))}
                      </Grid>
                    )}
                  </>
                )}
                {location.pathname === `/clase/${id}/profesori` && (
                  <>
                    <Typography variant="h2">Profesori</Typography>
                    {!classroom.teachers || classroom.teachers.length === 0 ? (
                      <Typography>Nu sunt profesori adaugați</Typography>
                    ) : (
                      <Grid container spacing={1}>
                        {classroom.teachers.map((teacher: any, index: any) => (
                          <Grid item xs={12} md={6} key={index}>
                            <List>
                              {user.position === "principal" && (
                                <AvatarItemList
                                  firstname={teacher.first_name}
                                  lastname={teacher.last_name}
                                  profilePhoto={teacher.profile_photo}
                                  onClick={() => {
                                    navigate(`/profesori/${teacher.id}`);
                                  }}
                                />
                              )}
                              {user.position === "teacher" && (
                                <AvatarItemList
                                  firstname={teacher.first_name}
                                  lastname={teacher.last_name}
                                  profilePhoto={teacher.profile_photo}
                                />
                              )}
                            </List>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </>
                )}
                {location.pathname === `/clase/${id}` && (
                    <DashboardStatistics selectedClassroom={id} pageType="classroom" bgColor="light"/>
                )}
                {location.pathname === `/clase/${id}/teste` && (
                  <>
                    <TestsHistory fromClassroom = {true} pageType="classroom" pageId={id} />
                  </>
                )}
              </Box>
            </BoxContent>
          )}
        </>
      )}
    </Box>
  );
};

export default Classroom;
