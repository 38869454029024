import { Navigate, useOutlet } from "react-router-dom";

import { useAuth } from "hooks/useAuth";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import ScrollBar from "components/ui/ScrollBar";

const LayoutPublicNoSidebar = () => {
    const outlet = useOutlet();

    // if (
    //     user &&
    //     user.firstname !== undefined &&
    //     user.firstname !== null &&
    //     user.firstname !== "" &&
    //     user.email_verified !== null &&
    //     localStorageUser !== "{\"theme\":\"light\"}"
    // ) {
    //     return <Navigate to="/statistici" replace />;
    // }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            // alignItems="stretch"
            spacing={0}
            // minHeight="100dvh"
            sx={{ backgroundColor: { xs: "background.paper", md: "transparent" } }}
        >
            <Grid
                item
                xs={12}
                sx={{
                    backgroundColor: "background.default",
                    borderRadius: { xs: 2, md: 0 },
                    height: "100dvh",
                    maxHeight: { xs: "auto", md: "100dvh" },
                }}
            >
                <ScrollBar style={{ maxHeight: "100%" }} forceVisible="x" autoHide={false}>
                    <Box sx={(theme) => ({ padding: { xs: theme.spacing(3, 0, 3, 0), md: theme.spacing(5, 1, 5, 1) } })}>
                        <Container maxWidth="xl">{outlet}</Container>
                    </Box>
                </ScrollBar>
            </Grid>
        </Grid>
    );
};

export default LayoutPublicNoSidebar;
