import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import DashboardBox from "components/dashboard/DashboardBox";
import {
    IDashboardBoxDataTextAmount,
    IDashboardBoxDataTableData,
    IDashboardBoxDataChart,
} from "components/dashboard/_interfaces";
import { getDashboardInfo } from "api/pages/dashboard";
import { getRomanNumber } from "utils/functions";
// import { getPrincipals } from "api/dashboard";
// import useAxios from "hooks/useAxios";
import { useAuth } from "hooks/useAuth";
// import { Link } from "react-router-dom";
import {useLocation, useNavigate} from "react-router-dom";
import DashboardFilters from "../form/DashboardFilters";

const DashboardStatistics = ({selectedClassroom = "", bgColor, selectedStudent = "", pageType = "", pageId = ""}: any) => {
    const navigate = useNavigate();
    const { update, user } = useAuth();
    const location = useLocation();

    const [statsStudents, setStatsStudents] = useState<IDashboardBoxDataTextAmount[] | undefined>(undefined);
    const [studentsTotal, setStudentsTotal] = useState<number>(0);
    const [teachersTotal, setTeachersTotal] = useState<number>(0);
    const [statsClasses, setStatsClasses] = useState<IDashboardBoxDataTextAmount[] | undefined>(undefined);
    const [statsTeachers, setStatsTeachers] = useState<IDashboardBoxDataTextAmount[] | undefined>(undefined);
    const [statsAverage, setStatsAverage] = useState<IDashboardBoxDataTextAmount[] | undefined>(undefined);
    const [statsTable, setStatsTable] = useState<IDashboardBoxDataTableData | undefined>(undefined);
    const [statsEvolution, setStatsEvolution] = useState<IDashboardBoxDataChart[] | undefined>(undefined);
    const [grade, setGrade] = useState<any[]>([]);
    const [grades, setGrades] = useState<any[]>([]);
    const [classroom, setClassroom] = useState<{ value: number; label: string; } | null>(null);
    const [classrooms, setClassrooms] = useState<any[]>([]);

    useEffect(() => {
        const grades = grade.map(({value}) => value);
        getDashboardInfo(
            {
                student: selectedStudent,
                classroom_grades: grades,
                classroom_id: selectedClassroom ? selectedClassroom : classroom ? classroom.value : null,
                pageType: pageType,
            }
        )
            .then((response) => {
                if (response.data.success) {
                    let today = new Date();
                    const firstDay = new Date(today.setDate(today.getDate() - today.getDay() + 1));

                    if (user.position === "principal" || user.position === "teacher") {
                        setGrades(response.data.data.filters.grades);
                        setClassrooms(response.data.data.filters.classrooms.map((item: { name: string; id: string, grade: number }) => {
                            return {
                                label: `(${getRomanNumber(item.grade)}) ${item.name}`,
                                value: item.id,
                                ...item,
                            };
                        }));
                        setStatsStudents(
                            response.data.data.students.grade?.map((item: { students_count: number; grade: number }) => {
                                return {
                                    title: getRomanNumber(item.grade),
                                    amount: item.students_count,
                                };
                            }) || [],
                        );
                        setStudentsTotal(response.data.data.students.count_students);
                        setStatsClasses(
                            response.data.data.classrooms?.map((item: { classrooms_count: number; grade: number }) => {
                                return {
                                    title: getRomanNumber(item.grade),
                                    amount: item.classrooms_count,
                                };
                            }) || [],
                        );
                    }
                    if (user.position === "principal") {
                        setStatsTeachers(
                            response.data.data.teachers?.map((item: { count_users: number; subject_name: string }) => {
                                return {
                                    title: item.subject_name,
                                    amount: item.count_users,
                                };
                            }) || [],
                        );
                        setTeachersTotal(response.data.data.teachers_count);
                    }
                    setStatsEvolution(
                        response.data.data.evolution
                            ?.filter((item: any) => item.data.some((el: any) => el !== null))
                            .map((item: { data: number[]; name: string; type: string }) => {
                                return {
                                    data: item.data,
                                    name: item.name,
                                    type: item.type,
                                    connectNulls: true,
                                    pointStart: Date.UTC(
                                        new Date(
                                            firstDay.getFullYear(),
                                            firstDay.getMonth(),
                                            firstDay.getDate() - 7 * (item.data.length - 1),
                                        ).getUTCFullYear(),
                                        new Date(
                                            firstDay.getFullYear(),
                                            firstDay.getMonth(),
                                            firstDay.getDate() - 7 * (item.data.length - 1),
                                        ).getUTCMonth(),
                                        new Date(
                                            firstDay.getFullYear(),
                                            firstDay.getMonth(),
                                            firstDay.getDate() - 7 * (item.data.length - 1),
                                        ).getUTCDate() + 1,
                                    ),
                                };
                            }) || [],
                    );

                    setStatsAverage(
                        response.data.data.general?.map((item: { subject: string; amount: number }) => {
                            return {
                                title: item.subject,
                                amount: item.amount,
                            };
                        }) || [],
                    );

                    const totalGeneratedTests = response.data.data.tests
                        ?.map(({ generated }: any) => generated)
                        .reduce((a: any, b: any) => a + b, 0);

                    const totalUnopenedTests = response.data.data.tests
                        ?.map(({ unopened }: any) => unopened)
                        .reduce((a: any, b: any) => a + b, 0);

                    const totalFinalizedTests = response.data.data.tests
                        ?.map(({ finalized }: any) => finalized)
                        .reduce((a: any, b: any) => a + b, 0);

                    const totalOpenTests = totalGeneratedTests - totalUnopenedTests - totalFinalizedTests;

                    setStatsTable({
                        columns: [
                            {
                                id: "discipline",
                                label: "Disciplina",
                            },
                            {
                                id: "unopened",
                                label: "Nedeschise",
                                numeric: true,
                            },
                            {
                                id: "open",
                                label: "Deschise",
                                numeric: true,
                            },
                            {
                                id: "finalized",
                                label: "Finalizate",
                                numeric: true,
                            },
                            {
                                id: "generated",
                                label: "Generate",
                                numeric: true,
                            },
                        ],
                        rows:
                            response.data.data.tests?.map(
                                (item: { discipline: string; generated: number; finalized: number; unopened: number; open: number }) => {
                                    return {
                                        discipline: item.discipline,
                                        unopened: item.unopened,
                                        open: item.generated - item.unopened - item.finalized,
                                        finalized: item.finalized,
                                        generated: item.generated,
                                    };
                                },
                            ) || [],
                        footer: [
                            {
                                discipline: "Total/Medie",
                                unopened: totalUnopenedTests,
                                open: totalOpenTests,
                                finalized: totalFinalizedTests,
                                generated: totalGeneratedTests,
                            },
                        ],
                    });
                    update("loading", { show: false });
                } else {
                    update("loading", { show: false });
                    update("error", { show: true, code: "A002" });
                }
            })
            .catch((err) => {
                update("loading", { show: false });
                update("error", { show: true, code: "A001" });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grade, classroom]);

    const handleGradeChange = (e: React.SyntheticEvent<Element, Event>, value: { value: number; label: string; }[]) => {
        setGrade(value);
        setClassroom(null);
    }

    const handleClassroomChange = (e: any, value: { value: number; label: string; } | null) => {
        // console.log(value.value)
        setClassroom(value);
    }


    return (
        <>
            {((user.position === "principal" || user.position === "teacher") && location.pathname === "/statistici") &&
                <DashboardFilters
                    grade={grade}
                    grades={grades}
                    classroom={classroom}
                    classrooms={classrooms}
                    handleClassroomChange={handleClassroomChange}
                    handleGradeChange={handleGradeChange}
                />
            }
            <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="stretch" mt="-20px">
                {((user.position === "principal" || user.position === "teacher") && location.pathname === "/statistici") && (
                    <Grid item xs={12} md={6} lg={user.position === "principal" ? 4 : 6}>
                        <DashboardBox
                            noDataText="Nu sunt clase adăugate"
                            type="numbers"
                            title="Clase"
                            icon="fal screen-users"
                            number={
                                statsClasses?.reduce((acc, cur) => {
                                    return acc + cur.amount;
                                }, 0) || 0
                            }
                            options={{
                                icon: "caret-down",
                                menu: [
                                    {
                                        icon: "plus",
                                        title: "Adaugă",
                                        action: () => {
                                            navigate("/clase/adauga");
                                        },
                                    },
                                    // {
                                    //   icon: "file-plus",
                                    //   title: "Importă",
                                    //   action: () => {
                                    //     navigate("/clase/importa");
                                    //   },
                                    // },
                                    {
                                        icon: "eye",
                                        title: "Listă clase",
                                        action: () => {
                                            navigate("/clase");
                                        },
                                    },
                                ],
                            }}
                            dataText={statsClasses}
                            loading={statsClasses === undefined}
                        />
                    </Grid>
                )}
                {user.position === "principal" && location.pathname === "/statistici" && (
                    <Grid item xs={12} md={6} lg={4}>
                        <DashboardBox
                            noDataText={teachersTotal === 0 ? "Nu sunt profesori adăugați" : "Profesorii nu au materii selectate"}
                            type="list"
                            title="Profesori"
                            icon="fal chalkboard-user"
                            number={teachersTotal}
                            options={{
                                icon: "caret-down",
                                menu: [
                                    {
                                        icon: "plus",
                                        title: "Adaugă",
                                        action: () => {
                                            navigate("/profesori/adauga");
                                        },
                                    },
                                    // {
                                    //   icon: "file-plus",
                                    //   title: "Importă",
                                    //   action: () => {
                                    //     navigate("/profesori/importa");
                                    //   },
                                    // },
                                    {
                                        icon: "eye",
                                        title: "Listă profesori",
                                        action: () => {
                                            navigate("/profesori");
                                        },
                                    },
                                ],
                            }}
                            dataText={statsTeachers}
                            loading={statsTeachers === undefined}
                        />
                    </Grid>
                )}
                {((user.position === "principal" || user.position === "teacher") && location.pathname === "/statistici") && (
                    <Grid item xs={12} md={6} lg={user.position === "principal" ? 4 : 6}>
                        <DashboardBox
                            noDataText="Nu sunt elevi adăugați"
                            type="numbers"
                            title="Elevi"
                            icon="fal user-graduate"
                            number={studentsTotal}
                            options={{
                                icon: "caret-down",
                                menu: [
                                    {
                                        icon: "plus",
                                        title: "Adaugă",
                                        action: () => {
                                            navigate("/elevi/adauga");
                                        },
                                    },
                                    // {
                                    //   icon: "file-plus",
                                    //   title: "Importă",
                                    //   action: () => {
                                    //     navigate("/elevi/importa");
                                    //   },
                                    // },
                                    {
                                        icon: "eye",
                                        title: "Listă elevi",
                                        action: () => {
                                            navigate("/elevi");
                                        },
                                    },
                                ],
                            }}
                            dataText={statsStudents}
                            loading={statsStudents === undefined}
                        />
                    </Grid>
                )}
                {Array.isArray(statsEvolution) && statsEvolution.length > 0 && (
                    <Grid item xs={12}>
                        <DashboardBox
                            bgColor={bgColor}
                            type="chart"
                            title="Evoluția săptămânală (centilă)"
                            icon="fal chart-line-up"
                            number={0}
                            dataChart={statsEvolution}
                        />
                    </Grid>
                )}
                <Grid item xs={12} lg={4}>
                    <DashboardBox
                        bgColor={bgColor}
                        noDataText="Nu sunt teste finalizate"
                        type="pie-chart"
                        title="Media generală"
                        icon="fal graduation-cap"
                        number={ statsAverage ?
                            parseFloat(
                                (
                                    statsAverage?.reduce((acc, cur) => {
                                        return acc + cur.amount;
                                    }, 0) / statsAverage.length
                                ).toFixed(1),
                            ) || 0 : 0
                        }
                        dataText={statsAverage}
                        loading={statsAverage === undefined}
                    />
                </Grid>

                <Grid item xs={12} lg={8}>
                    <DashboardBox
                        noDataText="Nu sunt teste generate"
                        type="table"
                        title="Teste"
                        icon="fal list-check"
                        number={0}
                        dataTable={statsTable}
                        loading={statsTable === undefined}
                        bgColor={bgColor}
                    />
                </Grid>

            </Grid>
        </>
    );
};

export default DashboardStatistics;
