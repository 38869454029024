import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import BoxContent from "components/ui/BoxContent";
import PageTitle from "components/ui/PageTitle";
import {APICheckStudents, APIGetStudent} from "api/pages/student";
import { APIGetAllClassrooms } from "api/pages/classrooms";
import Loading from "components/ui/Loading";
import { useAuth } from "hooks/useAuth";
import { APIUpdateStudent, APIPostStudent } from "api/pages/student";
import FormField from "components/form/FormField";
import ErrorsList from "components/ui/ErrorsList";
import { useLocation } from "react-router-dom";
import {arrayEquals, getRomanNumber, niceUserName} from "utils/functions";
import FormLabelBox from "components/ui/FormLabelBox";
import FormFieldBox from "components/ui/FormFieldBox";
import GenericButton from "components/ui/GenericButton";
import CustomButton from "../../components/ui/CustomButton";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import List from "@mui/material/List";
import AvatarItemList from "../../components/ui/AvatarItemList";
import DashboardStatistics from "../../components/dashboard/DashboardStatistics";

interface IFormStudentEdit {
  last_name: string;
  first_name: string;
  email: string;
  gender: string;
  classrooms: any[];
  // show_password?: boolean;
  // password?: string;
  // confirm_password?: string;
}

const schema = yup
  .object({
  })
  .required();

const Student = () => {
  const { id = "", action } = useParams();
  const { update } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const methods = useForm<IFormStudentEdit>({
    resolver: yupResolver(schema),
  });

  const [loadingClassrooms, setLoadingClassrooms] = useState<boolean>(true);
  const [loadingStudent, setLoadingStudent] = useState<boolean>(true);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [student, setStudent] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [classrooms, setClassrooms] = useState<any[]>([]);
  const [studentsCheck, setStudentsCheck] = useState("");

  useEffect(() => {
    setLoadingClassrooms(true);
    setLoadingStudent(true);
    if (id !== "") {
      getClassroomsHandler();
      APIGetStudent({ id: id })
        .then((response) => {
          if (response.data.success) {
            // console.log("getStudent OK", response);
            if (response.data.data !== null) {
              setStudent(response.data.data.item);
            } else {
              navigate("/pagina-nu-exista");
            }
            setLoadingStudent(false);
          } else {
            // console.log("getStudent err", response);
            update("error", { show: true, code: "A002" });
          }
        })
        .catch((err) => {
          // console.log("getStudent ERR", err);
          update("error", { show: true, code: "A001" });
        });
    } else {
      let locationArray = location.pathname.split("/");
      if (locationArray[locationArray.length - 1] === "adauga") {
        setStudent({
          last_name: "",
          first_name: "",
          email: "",
          gender: "",
        });
        APICheckStudents()
            .then((response) => {
              if(response.data.success) {
                getClassroomsHandler();
                setEditMode(true);
                setLoadingStudent(false);
              } else {
                update("error", { show: true, code: "A002" });
              }
            })
            .catch((err) => {
              if (err.response.status === 400) {
                let errors = err.response.data.data;
                setStudentsCheck(errors.values[0]);
                setEditMode(true);
                setLoadingStudent(false);
                setLoadingClassrooms(false);
              } else {
                update("error", { show: true, code: "A001" });
              }
            })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id !== "") {
      methods.clearErrors();
      setErrorMessage("");
      setEditMode(action === "editare");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, id]);

  const getClassroomsHandler = () => {
    APIGetAllClassrooms()
        .then((response) => {
          if (response.data.success) {
            // console.log("getClassroom OK", response);
            setClassrooms(
                response.data.data.items.map((item: { name: string; id: string, grade: number }) => {
                  return {
                    label: `(${getRomanNumber(item.grade)}) ${item.name}`,
                    value: item.id,
                    ...item,
                  };
                }),
            );
            setLoadingClassrooms(false);
          } else {
            update("error", { show: true, code: "A002" });
          }
        })
        .catch((err) => {
          // console.log("getClassroom ERR", err);
          update("error", { show: true, code: "A001" });
        });
  };

  // console.log(classrooms);

  const onSubmit: SubmitHandler<IFormStudentEdit> = (data) => {
    let studentClassrooms: number[] = [];
    let oldStudentClassrooms: number[] = [];

    data.classrooms && (studentClassrooms = data.classrooms.map(({ value }) => value));
    student.classrooms && (oldStudentClassrooms = student.classrooms.map(({ id }: any) => id));

    data.classrooms &&
      (data = {
        ...data,
        classrooms: data.classrooms.map((classroom: { label: string; value: string }) => ({
          name: classroom.label,
          id: classroom.value,
        })),
      });

    if (
      data.last_name !== student.last_name ||
      data.first_name !== student.first_name ||
      data.email !== student.email ||
      data.gender !== student.gender ||
      !arrayEquals(studentClassrooms, oldStudentClassrooms)
    ) {
      methods.clearErrors();
      setErrorMessage("");
      update("loading", { show: true, modal: true });
      if (id !== "") {
        APIUpdateStudent(id, {
          // ...(data.last_name !== student.last_name && { last_name: data.last_name }),
          // ...(data.first_name !== student.first_name && { first_name: data.first_name }),
          // ...(data.email !== student.email && { email: data.email }),
          // ...(data.gender !== student.gender && { gender: data.gender }),
          // ...(moment(data.birth_date).locale("ro").format("YYYY-MM-DD") !== moment(student.birth_date).locale("ro").format("YYYY-MM-DD") && data.birth_date ? { birth_date: moment(data.birth_date).locale("ro").format("YYYY-MM-DD")} : "") ,
          // ...(!arrayEquals(studentClassrooms, oldStudentClassrooms) && { classrooms: studentClassrooms }),
          last_name: data.last_name,
          first_name: data.first_name,
          email: data.email,
          classrooms: studentClassrooms,
          gender: data.gender,
        })
          .then((response) => {
            if (response.data.success) {
              // console.log("updateStudent OK", response);
              // logic after update
              setStudent(data);
              setEditMode(false);
              navigate(-1);
              update("loading", { show: false });
            } else {
              update("loading", { show: false });
              update("error", { show: true, code: "A002" });
            }
          })
          .catch((err) => {
            // console.log("updateStudent ERR", err);
            if (err.response.status === 400) {
              let errors = err.response.data.data;

              if (errors.last_name) {
                methods.setError("last_name", { type: "server", message: errors.last_name[0] });
              }
              if (errors.first_name) {
                methods.setError("first_name", { type: "server", message: errors.first_name[0] });
              }
              if (errors.email) {
                methods.setError("email", { type: "server", message: errors.email[0] });
              }
              if (errors.classrooms) {
                methods.setError("classrooms", { type: "server", message: errors.classrooms[0] });
              }
              if (errors.gender) {
                methods.setError("gender", { type: "server", message: errors.gender[0] });
              }
              update("loading", { show: false });
            } else {
              update("error", { show: true, code: "A001" });
            }
          });
      } else {
        APIPostStudent({
          last_name: data.last_name,
          first_name: data.first_name,
          email: data.email,
          classrooms: studentClassrooms,
          gender: data.gender,
          // password: data.password,
          // confirm_password: data.confirm_password,
        })
          .then((response) => {
            if (response.data.success) {
              // console.log("postStudent OK", response);
              // logic after update
              setStudent(data);
              setEditMode(false);
              navigate(`/elevi/${response.data.data.item.id}`, { replace: true });
              update("loading", { show: false });
            } else {
              update("loading", { show: false });
              update("error", { show: true, code: "A002" });
            }
          })
          .catch((err) => {
            // console.log("postStudent ERR", err);
            if (err.response.status === 400) {
              let errors = err.response.data.data;
              if (errors.last_name) {
                methods.setError("last_name", { type: "server", message: errors.last_name[0] });
              }
              if (errors.first_name) {
                methods.setError("first_name", { type: "server", message: errors.first_name[0] });
              }
              if (errors.email) {
                methods.setError("email", { type: "server", message: errors.email[0] });
              }
              if (errors.classrooms) {
                methods.setError("classrooms", { type: "server", message: errors.classrooms[0] });
              }
              if (errors.gender) {
                methods.setError("gender", { type: "server", message: errors.gender[0] });
              }
              update("loading", { show: false });
            } else {
              update("error", { show: true, code: "A001" });
            }
          });
      }
    } else {
      setErrorMessage("Nu ați modificat niciun câmp.");
    }
  };

  return (
    <Box component="main">
      {(loadingClassrooms || loadingStudent) ? (
        <Loading show={true} />
      ) : (
        <>
          <PageTitle
            back={true}
            actions={
              editMode ? (
                <GenericButton
                  type="close"
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              ) : (
                <GenericButton
                  type="edit"
                  onClick={() => {
                    navigate(`/elevi/${id}/editare`);
                  }}
                />
              )
            }
          >
            {id !== "" ? (
              <>
                Elev{" "}
                <strong style={{ fontWeight: 400 }}>
                  {niceUserName(student.first_name, student.last_name, student.email)}
                </strong>
              </>
            ) : (
              <>Adaugă elev</>
            )}
          </PageTitle>

          <BoxContent>
            <Box width="100%">
              {studentsCheck && editMode && id === "" ? (
                  <Typography>{studentsCheck}</Typography>
              ) : (
                  <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                      {/* <input
                    type="hidden"
                    name="show_password"
                    ref={methods.register("show_password").ref}
                    defaultValue={id === "" ? "true" : "false"}
                  /> */}

                      <Grid container spacing={1.5} alignItems="center">
                        <FormLabelBox title="Nume:" />

                        <FormFieldBox>
                          {!editMode ? (
                              student.last_name
                          ) : (
                              <FormField
                                  error={!!methods.formState.errors.last_name?.message}
                                  name="last_name"
                                  type="text"
                                  defaultValue={student.last_name}
                                  label="Nume"
                                  autoFocus={true}
                                  errorMessage={methods.formState.errors.last_name?.message}
                              />
                          )}
                        </FormFieldBox>

                        <FormLabelBox title="Prenume:" />

                        <FormFieldBox>
                          {!editMode ? (
                              student.first_name
                          ) : (
                              <FormField
                                  error={!!methods.formState.errors.first_name?.message}
                                  name="first_name"
                                  type="text"
                                  defaultValue={student.first_name}
                                  label="Prenume"
                                  errorMessage={methods.formState.errors.first_name?.message}
                              />
                          )}
                        </FormFieldBox>

                        <FormLabelBox title="Gen:" />

                        <FormFieldBox>
                          {!editMode ? (
                              (student.gender && student.gender === "M") ? "Masculin" : (student.gender && student.gender === "F") ? "Feminin" : ""
                          ) : (
                              <FormField
                                  error={!!methods.formState.errors.gender?.message}
                                  name="gender"
                                  type="select"
                                  defaultValue={student.gender}
                                  options={[{
                                    label: "Feminin",
                                    value: "F",
                                  }, {
                                    label: "Masculin",
                                    value: "M",
                                  }]}
                                  label="Gen"
                                  errorMessage={methods.formState.errors.gender?.message}
                              />
                          )}
                        </FormFieldBox>

                        <FormLabelBox title="E-mail:" />

                        <FormFieldBox>
                          {!editMode ? (
                              student.email
                          ) : (
                              <FormField
                                  error={!!methods.formState.errors.email?.message}
                                  name="email"
                                  type="text"
                                  defaultValue={student.email}
                                  label="E-mail"
                                  errorMessage={methods.formState.errors.email?.message}
                                  inputProps={{
                                    autoCapitalize: 'none',
                                  }}
                              />
                          )}
                        </FormFieldBox>

                        <FormLabelBox title="Clasă:" />

                        <FormFieldBox>
                          {!editMode ? (
                              student.classrooms ? (
                                  <>
                                    {student.classrooms.map((classroom: any, index: number) => (
                                        <Box key={index}>
                                          <Link to={`/clase/${classroom.id}`}>({getRomanNumber(classroom.grade)}) {classroom.name}</Link>
                                        </Box>
                                    ))}
                                  </>
                              ) : (
                                  <Box>Nimic selectat</Box>
                              )
                          ) : (
                              <FormField
                                  name="classrooms"
                                  type="autocomplete"
                                  defaultValue={
                                    student.classrooms
                                        ? student.classrooms.map((item: { name: string; id: string, grade: number }) => {
                                          return {
                                            label: `(${getRomanNumber(item.grade)}) ${item.name}`,
                                            value: item.id,
                                            ...item,
                                          };
                                        })
                                        : []
                                  }
                                  label="Alege clasa"
                                  multiple={true}
                                  options={classrooms}
                                  errorMessage={methods.formState.errors.classrooms?.message}
                                  error={!!methods.formState.errors.classrooms?.message}
                              />
                          )}
                        </FormFieldBox>

                        {/* {editMode && id === "" && (
                      <>
                        <FormLabelBox title="Parola:" />

                        <FormFieldBox>
                          <FormField
                            name="password"
                            type="password"
                            label="Parola"
                            errorMessage={methods.formState.errors.password?.message}
                          />
                        </FormFieldBox>

                        <FormLabelBox title="Confirmă parola:" />

                        <FormFieldBox>
                          <FormField
                            name="confirm_password"
                            type="password"
                            label="Confirmă parola"
                            errorMessage={methods.formState.errors.confirm_password?.message}
                          />
                        </FormFieldBox>
                      </>
                    )} */}
                        {editMode && (
                            <>
                              <FormLabelBox />

                              <FormFieldBox>
                                <CustomButton submit buttonType="textIcon" bgcolor="dark">
                                  {id !== "" ? "SALVEAZĂ ELEV" : "ADAUGĂ ELEV"}
                                </CustomButton>
                                <ErrorsList show={errorMessage !== ""}>{errorMessage}</ErrorsList>
                              </FormFieldBox>
                            </>
                        )}
                      </Grid>
                    </form>
                  </FormProvider>
              )}

            </Box>
          </BoxContent>
          {!editMode && (
              <BoxContent mt={3}>
                  <DashboardStatistics selectedStudent={id} bgColor="light"/>
              </BoxContent>
          )}
        </>
      )}
    </Box>
  );
};

export default Student;
