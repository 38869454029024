import axios from "axios";
import Button from "@mui/material/Button";
import {APIPostExternalReports, APIPostReport} from "api/pages/report";
import Loading from "components/ui/Loading";
import { useAuth } from "hooks/useAuth";
import React, { useEffect, useRef, useState } from "react";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import {PDFDownloadLink} from '@react-pdf/renderer';
import ReportComponent from "components/reports/school/Report";
import ReportDigitalLiteracy from "../../components/reports/digitalLiteracy/Report";
import ReportLiteracy from "../../components/reports/literacy/Report";
import ReportPdf from "../../components/reports/school/pdf/Report";
import ReportPdfLiteracy from "../../components/reports/literacy/pdf/Report";
import ReportPdfDigitalLiteracy from "../../components/reports/digitalLiteracy/pdf/Report";
import Icon from "../../components/ui/Icon";
import {authInterceptor} from "../../api/apiClient";

const Report = () => {
  const reportPrintRef = useRef(null);
  const { id = "" } = useParams();
  const { update } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [reportData, setReportData] = useState<any>();
  const [gradeLevels, setGradeLevel] = useState<any>();
  const [graphData, setGraphData] = useState<any>();
  const [skillsData, setSkillsData] = useState<any>();
  const [generatePdf, setGeneratePdf] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const payload = searchParams.get("payload");

  useEffect(() => {
    if (id !== "") {
      setLoading(true);

      if(payload !== null) {
        APIPostExternalReports({payload: payload})
            .then((response) => {
              if (response.data.success) {
                setReportData(response.data.data);
                setGradeLevel(response.data.data.grade_levels);
                setGraphData(response.data.data.graph_data);
                setSkillsData(response.data.data.skills_data);
                setLoading(false);
              } else {
                update("error", { show: true, code: "A002" });
              }
            })
            .catch((err) => {
              update("error", { show: true, code: "A001" });
            })
            .finally(() => {
              axios.interceptors.request.eject(authInterceptor);
            });
      } else {
        let locationArray = location.pathname.split("/");
        const archivedLocation = locationArray[locationArray.length - 3];
        APIPostReport({ test_id: id, isArchived: archivedLocation === "teste-arhivate"})
            .then((response) => {
              if (response.data.success) {
                setReportData(response.data.data);
                setGradeLevel(response.data.data.grade_levels);
                setGraphData(response.data.data.graph_data);
                setSkillsData(response.data.data.skills_data);
                setLoading(false);
              } else {
                update("error", { show: true, code: "A002" });
              }
            })
            .catch((err) => {
              update("error", { show: true, code: "A001" });
            });
      }

    } else {
      navigate("/teste");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handlePrint = useReactToPrint({
    content: () => reportPrintRef.current,
  });

  return (
    <>
      {loading ? (
        <Loading show={true} />
      ) : (
        <>
          <div ref={reportPrintRef}>
            {reportData.test_mode.id === 5 ? (
                <ReportLiteracy
                  reportData={reportData}
                  gradeLevels={gradeLevels}
                  graphData={graphData}
                  skillsData={skillsData}
                />
            ) : reportData.test_mode.id === 6 ? (
              <ReportDigitalLiteracy
                reportData={reportData}
                gradeLevels={gradeLevels}
                graphData={graphData}
                skillsData={skillsData}
              />
          ) : (
              <ReportComponent
                reportData={reportData}
                gradeLevels={gradeLevels}
                graphData={graphData}
                skillsData={skillsData}
              />
              )}
          </div>
          <div className="print-bar screen no-print">
            <Button type="submit" variant="contained" onClick={handlePrint} style={{ marginRight: 20 }}>
              Tipărește
            </Button>
            {!generatePdf ? (
                <Button variant="contained" onClick={() => setGeneratePdf(true)} >
                  Generează pdf
                </Button>
            ) : (
                reportData.test_mode.id === 5 ? (
                    <PDFDownloadLink document={
                      <ReportPdfLiteracy
                          reportData={reportData}
                          gradeLevels={gradeLevels}
                          graphData={graphData}
                          skillsData={skillsData}
                      />
                    } fileName="RaportEvaluare.pdf">
                      {({ blob, url, loading, error }) =>
                          loading ? <>Se generează <Icon icon="far circle-notch" spin={true} className="fa-faster" fixedWidth={true} sx={{display: "inline"}}/></> : "Descarcă pdf"
                      }
                    </PDFDownloadLink>
                ) : reportData.test_mode.id === 6 ? (
                    <PDFDownloadLink document={
                      <ReportPdfDigitalLiteracy
                          reportData={reportData}
                          gradeLevels={gradeLevels}
                          graphData={graphData}
                          skillsData={skillsData}
                      />
                    } fileName="RaportEvaluare.pdf">
                      {({ blob, url, loading, error }) =>
                          loading ? <>Se generează <Icon icon="far circle-notch" spin={true} className="fa-faster" fixedWidth={true} sx={{display: "inline"}}/></> : "Descarcă pdf"
                      }
                    </PDFDownloadLink>
                ) : (
                    <PDFDownloadLink document={
                      <ReportPdf
                          reportData={reportData}
                          gradeLevels={gradeLevels}
                          graphData={graphData}
                          skillsData={skillsData}
                      />
                    } fileName="RaportEvaluare.pdf">
                      {({ blob, url, loading, error }) =>
                          loading ? <>Se generează <Icon icon="far circle-notch" spin={true} className="fa-faster" fixedWidth={true} sx={{display: "inline"}}/></> : "Descarcă pdf"
                      }
                    </PDFDownloadLink>
                    )
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Report;
