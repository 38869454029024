import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import BoxContent from "components/ui/BoxContent";
import PageTitle from "components/ui/PageTitle";
import { useLocation } from "react-router-dom";

type LocationProps = {
  state: {
    mode: number;
  };
};

const TestFinished = () => {
  const location = useLocation() as unknown as LocationProps;

  const mode = location.state?.mode || "";

  return (
    <Box component="main">
      {mode === 1 && (
          <>
          <PageTitle back={true} url={"/teste/teste-finalizate"}>
            Felicitări, ai terminat testul!
          </PageTitle>
          <BoxContent>
          <Typography>
            Raportul tău BRIO va fi disponibil în pagina <b>Teste școlare</b>, secțiunea <b>Teste finalizate</b>.
          </Typography>
          </BoxContent>
        </>
      )}
      {mode === 2 && (
        <>
          <PageTitle back={true} url={"/teste-antrenament/teste-finalizate"}>
            Felicitări, ai terminat testul!
          </PageTitle>
          <BoxContent>
            <Typography>
              Răspunsurile tale sunt disponibile în pagina <b>Teste antrenament</b>, secțiunea <b>Teste finalizate</b>.
            </Typography>
          </BoxContent>
        </>
      )}
      {mode === 3 && (
          <>
            <PageTitle back={true} url={"/teste-bac/teste-finalizate"}>
              Felicitări, ai terminat testul!
            </PageTitle>
            <BoxContent>
              <Typography>
                Raportul tău BRIO va fi disponibil în pagina <b>Teste bacalaureat</b>, secțiunea <b>Teste finalizate</b>.
              </Typography>
            </BoxContent>
          </>

      )}
      {mode === 4 && (
          <>
            <PageTitle back={true} url={"/teste-evaluare/teste-finalizate"}>
              Felicitări, ai terminat testul!
            </PageTitle>
            <BoxContent>
              <Typography>
                Raportul tău BRIO va fi disponibil în pagina <b>Teste evaluare</b>, secțiunea <b>Teste finalizate</b>.
              </Typography>
            </BoxContent>
          </>

      )}
      {mode === 5 && (
          <>
            <PageTitle back={true} url={"/teste-literatie/teste-finalizate"}>
              Felicitări, ai terminat testul!
            </PageTitle>
            <BoxContent>
              <Typography>
                Raportul tău BRIO va fi disponibil în pagina <b>Teste literație</b>, secțiunea <b>Teste finalizate</b>.
              </Typography>
            </BoxContent>
          </>

      )}
      {mode === 6 && (
          <>
            <PageTitle back={true} url={"/teste-literatie-digitala/teste-finalizate"}>
              Felicitări, ai terminat testul!
            </PageTitle>
            <BoxContent>
              <Typography>
                Raportul tău BRIO va fi disponibil în pagina <b>Teste literație digitală</b>, secțiunea <b>Teste finalizate</b>.
              </Typography>
            </BoxContent>
          </>
      )}
      {mode === 7 && (
          <>
            <PageTitle back={true} url={"/teste-proprii/teste-finalizate"}>
              Felicitări, ai terminat testul!
            </PageTitle>
            <BoxContent>
              <Typography>
                Răspunsurile tale vor fi disponibile în pagina <b>Teste proprii</b>, secțiunea <b>Teste finalizate</b>.
              </Typography>
            </BoxContent>
          </>

      )}
    </Box>
  );
};

export default TestFinished;
