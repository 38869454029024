import Typography from "@mui/material/Typography";
import BoxContent from "../ui/BoxContent";
import Box from "@mui/material/Box";
import {MathJax} from "better-react-mathjax";
import Icon from "../ui/Icon";
import Grid from "@mui/material/Grid";

const LiteracyAnswersReport = ({locationUrl, studentAnswers} : {locationUrl: string, studentAnswers: any}) => {

return (
    <>
        {locationUrl === "raspunsuri" ? (
            <>
                {studentAnswers.test_mode.id !== 2 && (
                    <Typography variant="h3" mb={3} mt={3}>
                        Centilă elev: {studentAnswers.score}
                    </Typography>
                )}

                <BoxContent>
                    <>
                        {studentAnswers.test_mode.id !== 2 && (
                            <>
                                <Typography variant="h4">Disclaimer:</Typography>
                                <p>
                                    Unii itemi sunt mai grei, alți itemi sunt mai ușori, astfel <b>fiecare item este ponderat</b>{" "}
                                    diferit în scorul obținut de copil.
                                </p>
                                <p>
                                    Dat fiind faptul că itemii variază ca nivel de dificultate, vă rugăm să luați în considerare
                                    posibilitatea ca un item pe care îl percepeți ca fiind greșit{" "}
                                    <b>să fie, în fapt, foarte dificil de înțeles și de rezolvat</b>. Itemii noștri sunt alcătuiți
                                    și verificați de profesori extrem de competenți. Totuși, admitem faptul că pot apărea erori
                                    umane în procesul de redactare și tehnoredactare de itemi și astfel, dacă observați că un item
                                    nu este doar dificil, ci este în mod clar greșit, vă rugăm să ne scrieți la adresa
                                    office@brio.ro și vom verifica din nou itemii semnalați de dumneavoastră.{" "}
                                </p>
                                <p>
                                    În metodologia de testare și scorare IRT, utilizată în testele BRIO,{" "}
                                    <b>un răspuns greșit, respectiv un răspuns omis au același efect</b> asupra scorului final.
                                    Așadar, deși ele nu vor avea efecte diferite asupra scorului elevului, în continuare veți primi
                                    o evidență în care itemii greșiți vor fi marcați diferit față de cei omiși, pentru a putea avea
                                    o situație clară asupra performanței din cadrul testului.
                                </p>
                            </>
                        )}

                        <ul>
                            <li>testul a fost alcătuit din {studentAnswers.total_items} itemi</li>
                            <li>ați omis {studentAnswers.total_no_answers} itemi</li>
                            <li>ați răspuns corect la {studentAnswers.total_right_answers} itemi</li>
                            <li>ați răspuns greșit la {studentAnswers.total_wrong_answers} itemi</li>
                        </ul>
                    </>
                </BoxContent>
            </>
        ) : (
            <BoxContent>
                <ul>
                    <li>testul este alcătuit din {studentAnswers.total_items} itemi</li>
                </ul>
            </BoxContent>
        )}

        {studentAnswers.items.map((item: any, index: any) => (
            <Box key={index}>
                <Box mt={3} mb={3} width="100%" >
                    <BoxContent>
                        <MathJax style={{ width: "100%" }}>
                            {item.q_data && (
                                <Typography component="div" width="100%" variant="h4" mb={2}>
                                    <Box dangerouslySetInnerHTML={{ __html: item.q_data }}></Box>
                                </Typography>
                            )}

                            {item.q_obs && (
                                <>
                                    <Typography variant="h4">TEXT</Typography>
                                    <Typography component="div" width="100%" variant="body1" mb={2}>
                                        <Box dangerouslySetInnerHTML={{ __html: item.q_obs }}></Box>
                                    </Typography>
                                </>
                            )}

                        </MathJax>
                    </BoxContent>
                </Box>
                {item.questions.map((question: any, index: any) => (
                    <Box sx={{marginTop: {xs: 2, md: 3}, marginBottom: {xs: 2, md: 3}}} width="100%" key={index}>
                        <BoxContent>
                            <Box width="100%" sx={{display: {xs: "block", md: "flex"}}} alignItems="center">
                                {locationUrl === "raspunsuri" && (
                                    <Box sx={{marginRight: {xs: 0, md: 3}, textAlign: {xs: "right", md: "center"}, fontSize: {xs: "2rem" ,md: "2.5rem"}}}>
                                        {question.is_not_answered && (
                                            <Icon
                                                icon="fas circle-xmark"
                                                fixedWidth={true}
                                                sx={{ color: "text.disabled" }}
                                            />
                                        )}
                                        {question.is_wrong_answer && (
                                            <Icon
                                                icon="fas circle-xmark"
                                                fixedWidth={true}
                                                sx={{ color: "error.main" }}
                                            />
                                        )}
                                        {question.is_right_answer && (
                                            <Icon
                                                icon="fas circle-check"
                                                fixedWidth={true}
                                                sx={{ color: "success.main" }}
                                            />
                                        )}
                                    </Box>
                                )}

                                <MathJax style={{ width: "100%"}} inline>
                                    {question.q_obs && (
                                        <>
                                            <Typography variant="h4">ENUNȚ</Typography>
                                            <Typography component="div" width="100%" variant="body1" mb={2} sx={{fontSize: {xs: "0.875rem", md: "1rem"}}}>
                                                <Box dangerouslySetInnerHTML={{ __html: question.q_obs }}></Box>
                                            </Typography>
                                        </>
                                    )}
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Box width="fit-content" minWidth="100%">
                                                    <Box
                                                        mb={2}
                                                        sx={{
                                                            padding: 2,
                                                            border: "1px solid",
                                                            borderColor:
                                                                question.user_answer === "a" && locationUrl === "raspunsuri" && question.is_right_answer
                                                                    ? "#00BA3B"
                                                                    : question.user_answer === "a" && locationUrl === "raspunsuri" && !question.is_right_answer
                                                                        ? "error.main"
                                                                        : "#fff",
                                                        }}
                                                        width="100%"
                                                    >
                                                        <Typography component="div" dangerouslySetInnerHTML={{ __html: question.q_responses[0].response }} />
                                                    </Box>
                                                    <Box
                                                        mb={2}
                                                        sx={{
                                                            padding: 2,
                                                            border: "1px solid",
                                                            borderColor:
                                                                question.user_answer === "b" && locationUrl === "raspunsuri" && question.is_right_answer
                                                                    ? "#00BA3B"
                                                                    : question.user_answer === "b" && locationUrl === "raspunsuri" && !question.is_right_answer
                                                                        ? "error.main"
                                                                        : "#fff",
                                                        }}
                                                        width="100%"
                                                    >
                                                        <Typography component="div" dangerouslySetInnerHTML={{ __html: question.q_responses[1].response }} />
                                                    </Box>
                                                    <Box
                                                        mb={2}
                                                        sx={{
                                                            padding: 2,
                                                            border: "1px solid",
                                                            borderColor:
                                                                question.user_answer === "c" && locationUrl === "raspunsuri" && question.is_right_answer
                                                                    ? "#00BA3B"
                                                                    : question.user_answer === "c" && locationUrl === "raspunsuri" && !question.is_right_answer
                                                                        ? "error.main"
                                                                        : "#fff",
                                                        }}
                                                        width="100%"
                                                    >
                                                        <Typography component="div" dangerouslySetInnerHTML={{ __html: question.q_responses[2].response }} />
                                                    </Box>
                                                    <Box
                                                        mb={2}
                                                        sx={{
                                                            padding: 2,
                                                            border: "1px solid",
                                                            borderColor:
                                                                question.user_answer === "d" && locationUrl === "raspunsuri" && question.is_right_answer
                                                                    ? "#00BA3B"
                                                                    : question.user_answer === "d" && locationUrl === "raspunsuri" && !question.is_right_answer
                                                                        ? "error.main"
                                                                        : "#fff",
                                                        }}
                                                        width="100%"
                                                    >
                                                        <Typography component="div" dangerouslySetInnerHTML={{ __html: question.q_responses[3].response }} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </MathJax>
                            </Box>
                        </BoxContent>
                    </Box>
                ))}
            </Box>
        ))}
    </>
)
}

export default LiteracyAnswersReport;