import Box from "@mui/material/Box";
import PageTitle from "components/ui/PageTitle";
import DashboardStatistics from "../components/dashboard/DashboardStatistics";

const Dashboard = () => {

  return (
    <Box component="main">
      <PageTitle>Statistici</PageTitle>
     <DashboardStatistics />
    </Box>
  );
};

export default Dashboard;
