import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import PageTitle from "components/ui/PageTitle";
import {useLocation, useParams, useSearchParams} from "react-router-dom";
import {APIPostExternalFinishTest, APIPostFinishTest} from "api/pages/test";
import { useAuth } from "hooks/useAuth";
import Loading from "components/ui/Loading";
import SchoolAnswersReport from "../../components/answersReports/SchoolAnswersReport";
import LiteracyAnswersReport from "../../components/answersReports/LiteracyAnswersReport";
import axios from "axios";
import {authInterceptor} from "../../api/apiClient";

const StudentAnswersReport = () => {
  const { id = "" } = useParams();
  const location = useLocation();
  const { update } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [studentAnswers, setStudentAnswers] = useState<any>();
  const [locationUrl, setLocationUrl] = useState<string>("");

    const [searchParams, setSearchParams] = useSearchParams();
    const payload = searchParams.get("payload");

  useEffect(() => {
    if (id !== "") {
      setLoading(true);
        if(payload !== null) {
            setLocationUrl("raspunsuri");
            APIPostExternalFinishTest({ payload: payload})
                .then((response) => {
                    if (response.data.success) {
                        // console.log("PostFinishTest OK", response);
                        setStudentAnswers(response.data.data);
                        setLoading(false);
                    } else {
                        update("error", { show: true, code: "A002" });
                    }
                })
                .catch((err) => {
                    update("error", { show: true, code: "A001" });
                })
                .finally(() => {
                    axios.interceptors.request.eject(authInterceptor);
                });
        } else {
            let locationArray = location.pathname.split("/");
            setLocationUrl(locationArray[locationArray.length - 2]);
            const archivedLocation = locationArray[locationArray.length - 3];
            APIPostFinishTest({test: id, isArchived: archivedLocation === "teste-arhivate"})
                .then((response) => {
                    if (response.data.success) {
                        // console.log("PostFinishTest OK", response);
                        setStudentAnswers(response.data.data);
                        setLoading(false);
                    } else {
                        update("error", {show: true, code: "A002"});
                    }
                })
                .catch((err) => {
                    // console.log("PostFinishTest ERR", err);
                    update("error", {show: true, code: "A001"});
                });
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Box component="main">
      <PageTitle>
        {locationUrl === "previzualizare" ? "Previzualizare sesiune testare:" : "Sesiune testare:"} #{id}
      </PageTitle>
      {loading ? (
        <Loading show={true} />
      ) : (
          studentAnswers.test_mode.id === 5 ? (
              <LiteracyAnswersReport locationUrl={locationUrl} studentAnswers={studentAnswers} />
              ) : (
              <SchoolAnswersReport locationUrl={locationUrl} studentAnswers={studentAnswers} />
          )
      )}
    </Box>
  );
};

export default StudentAnswersReport;
